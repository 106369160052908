<template>
    <div>
        <ul
            class="
                faq-tabs
                d-flex
                flex-wrap
                align-items-center
                justify-content-center
                mb-4
            "
        >
            <li class="mx-2 pb-2">
                <button
                    @click="getCategory('general')"
                    class="btn text-uppercase"
                    :class="[
                        currentCategory == 'general'
                            ? 'text-brand font-weight-600'
                            : 'text-color font-weight-normal',
                    ]"
                >
                    GENERAL
                </button>
            </li>
            <li class="mx-2 pb-2">
                <button
                    @click="getCategory('partner')"
                    class="btn text-uppercase"
                    :class="[
                        currentCategory == 'partner'
                            ? 'text-brand font-weight-600'
                            : 'text-color font-weight-normal',
                    ]"
                >
                    PARTNERS
                </button>
            </li>
            <li class="mx-2 pb-2">
                <button
                    @click="getCategory('user')"
                    class="btn text-uppercase"
                    :class="[
                        currentCategory == 'user'
                            ? 'text-brand font-weight-600'
                            : 'text-color font-weight-normal',
                    ]"
                >
                    USERS
                </button>
            </li>
            <li class="mx-2 pb-2">
                <button
                    @click="getCategory('module')"
                    class="btn text-uppercase"
                    :class="[
                        currentCategory == 'module'
                            ? 'text-brand font-weight-600'
                            : 'text-color font-weight-normal',
                    ]"
                >
                    MODULES
                </button>
            </li>
        </ul>
        <div class="row align-items-center">
            <div class="col-lg-10 col-xl-7 mx-auto">
                <div v-if="loading">
                    <div class="mb-3">
                        <b-skeleton
                            animation="wave"
                            height="3rem"
                            width="100%"
                        ></b-skeleton>
                        <b-skeleton-img
                            no-aspect
                            height="100px"
                        ></b-skeleton-img>
                    </div>
                    <div class="mb-3">
                        <b-skeleton
                            animation="wave"
                            height="3rem"
                            width="100%"
                        ></b-skeleton>
                    </div>
                </div>
                <div v-else class="accordion" role="tablist">
                    <b-card
                        v-for="(faq, index) in FAQuestions"
                        :key="index"
                        no-body
                        class="mb-1"
                    >
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                class="
                                    d-flex
                                    align-items-center
                                    justify-content-between
                                "
                                block
                                v-b-toggle="`faq-${index}`"
                            >
                                <div class="d-flex align-items-center">
                                    <span class="index">{{ index + 1 }}</span>
                                    <p class="mb-0 mx-sm-2">
                                        {{ faq.question }}
                                    </p>
                                </div>
                                <span
                                    class="
                                        arrow
                                        d-inline-flex
                                        align-items-center
                                        justify-content-center
                                    "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="10"
                                        viewBox="0 0 11.525 6.763"
                                    >
                                        <path
                                            d="M0,0,4.349,4.349,0,8.7"
                                            transform="translate(1.414 5.349) rotate(-90)"
                                            fill="none"
                                            stroke="#c6c6c6"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </svg>
                                </span>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            :id="`faq-${index}`"
                            visible
                            accordion="my-accordion"
                            role="tabpanel"
                        >
                            <b-card-body>
                                <b-card-text>
                                    <pre>
                                        {{ faq.answer }}
                                    </pre>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
            <div class="col-xl-5 d-none d-xl-block">
                <img class="mw-100" src="@/assets/imgs/faq-img.png" alt="faq" />
            </div>
        </div>
    </div>
</template>

<script>
import { getFAQ } from "@/API/faq";

export default {
    name: "FAQ",
    data() {
        return {
            loading: true,
            currentCategory: "general",
            FAQuestions: [],
        };
    },
    methods: {
        getCategory(type) {
            this.loading = true;
            this.currentCategory = type;
            getFAQ(type).then((res) => {
                this.loading = false;
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log(data);
                    this.FAQuestions = data;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                }
            });
        },
    },
    mounted() {
        this.getCategory("general");
    },
};
</script>
