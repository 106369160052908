import { API, logError } from "./index";

const signUpUser = async (data) => {
    try {
        return await API().post('/register', data);
    } catch (error) {
        return logError(error);
    }
}

const verifyEmail = async (data) => {
    try {
        return await API().post('/submit-Code', data);
    } catch (error) {
        return logError(error);
    }
}

const loginUser = async (data) => {
    try {
        return await API().post('/login', data);
    } catch (error) {
        return logError(error);
    }
}

const logOutUser = async () => {
    try {
        return await API().get('/logout');
    } catch (error) {
        return logError(error);
    }
}

const updatePassword = async (data) => {
    try {
        return await API().put('/update-Password', data);
    } catch (error) {
        return logError(error);
    }
}

const updateUserInfo = async (data) => {
    try {
        return await API().post('/update', data);
    } catch (error) {
        return logError(error);
    }
}

const getUser = async () => {
    try {
        return await API().get('/user-data');
    } catch (error) {
        return logError(error);
    }
}

const resetPassword = async (data) => {
    try {
        return await API().post('/reset-password', data);
    } catch (error) {
        return logError(error);
    }
}


export {
    signUpUser,
    verifyEmail,
    loginUser,
    logOutUser,
    updatePassword,
    updateUserInfo,
    getUser,
    resetPassword
};