import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import ar from './ar';

Vue.use(VueI18n);

const lang = window.localStorage.getItem('dlpLang') || 'en';
const direction = lang == 'en' ? 'ltr' : 'rtl';
document.querySelector('html').setAttribute('dir', direction);
document.querySelector('html').setAttribute('lang', lang);
document.querySelector('body').setAttribute('dir', direction);

export default new VueI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages: { en, ar }
});