export default {
    methods: {
        textSummary(text, textLength) {
            if (text.length > textLength) {
                let validText = text.slice(0, textLength);
                if (text[textLength - 1] == " ") {
                    validText = text.slice(0, textLength - 2);
                }

                return `${validText}...`;
            }
            return text;
        }
    }
}