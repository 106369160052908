export default {
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://api-stg.dlp-me.com/api/auth',
    firebaseConfig: {
        apiKey: 'AIzaSyDwly1lflXeMV6Nbb0OHgEfVGwDA-0L7Qg',
        authDomain: 'dlpapp.firebaseapp.com',
        projectId: 'dlpapp',
        storageBucket: 'dlpapp.appspot.com',
        messagingSenderId: '687387444871',
        appId: '1:687387444871:web:022f17ec8a5f0c117e1a22',
        measurementId: 'G-08LPW1XP4N'
    },
    vapidKey: 'BJG8u4IBeAbScnwisiXFr78JDqeQwTNGeRpem1B8WCnD6IJTw_A8Dyj_npy3RmUUZo9iWta3l0r9KUkNVIgRhxc'
}