<template>
    <footer class="main-footer pt-3 pb-sm-3"
    :class="{'p--b' : isLogin}">
        <div class="container">
            <div
                class="
                    d-flex
                    flex-wrap flex-lg-nowrap
                    align-items-center
                    justify-content-center justify-content-lg-between
                "
            >
                <span class="copyright"
                    >© {{ thisYear }} PLP System. All Rights Reserved.</span
                >
                <ul
                    class="
                        d-flex
                        flex-wrap
                        align-items-center
                        justify-content-center justify-content-sm-between
                    "
                >
                    <li class="nav-item">
                        <router-link
                            :to="`${isLogin ? '/dashboard' : '/'}`"
                            class="nav-link px-0 px-lg-2"
                            >{{ $t('home') }}</router-link
                        >
                    </li>
                    <li class="nav-item">
                        <router-link to="/contact-us" class="nav-link"
                            >{{ $t('contact') }}</router-link
                        >
                    </li>
                    <li class="nav-item">
                        <router-link to="/faq" class="nav-link"
                            >FAQ</router-link
                        >
                    </li>
                    <li class="nav-item">
                        <button
                            v-b-modal.terms-modal
                            type="button"
                            class="nav-link"
                        >
                            Terms && Conditions
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "MainFooter",
    computed: {
        thisYear() {
            return new Date().getFullYear() || 2021;
        },
        isLogin() {
            return this.$store.state.auth?.isLogin || false;
        },
    },
};
</script>
