<template>
    <div class="d-flex flex-column align-items-center">
        <h3 class="font-weight-600 mb-3 text-center">
            Subscribe to Our Newsletter
        </h3>
        <form @submit.prevent="subscribeInNewsletter()">
            <div class="d-flex flex-column flex-sm-row align-items-center">
                <input
                    type="text"
                    class="form-control mx-2 mb-3 mb-sm-0"
                    :placeholder="$t('email')"
                    v-model.trim="email"
                />
                <button
                    type="submit"
                    :disabled="sending"
                    class="btn btn-subscribe mx-2">
                    <b-spinner v-if="sending" variant="light"></b-spinner>
                    <span v-else class="text-white">Subscribe</span>
                </button>
            </div>
            <div v-if="errors.includes('email')" class="mx-2 py-2 text-center">
                <p class="text-danger mb-0">
                    {{ $t("validEmailError") }}
                </p>
            </div>
        </form>
    </div>
</template>

<script>
    import { subscribeNewsletter } from "@/API/newsletter";
    export default {
        name: "Subscribe",
        data() {
            return {
                sending: false,
                email: null,
                errors: []
            }
        },
        methods: {
            validateEmail() {
                const re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const isValidEmail = re.test(String(this.email).toLowerCase());
                if (!isValidEmail) {
                    this.errors.push('email');
                    return false;
                }
                return true;
            },
            subscribeInNewsletter() {
                this.errors = [];
                if (!this.validateEmail()) return;

                const data = {
                    email: this.email
                }
                this.sending = true;
                subscribeNewsletter(data).then(res => {
                    if (res.status == 200) {
                        const data = res.data;
                        // console.log('subscribed', data);
                        this.$notify(data.message);
                    }

                    if (res?.response?.status == 500) {
                        this.$router.push("/500");
                        return;
                    }

                    this.sending = false;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>