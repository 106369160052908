import { API, logError } from "./index";

const subscribeNewsletter = async (data) => {
    try {
        return await API().post(`/subscribe`, data);
    } catch (error) {
        return logError(error);
    }
};

export { subscribeNewsletter }