import { API, logError } from "./index";

const getSlider = async () => {
    try {
        return await API().get(`/slider`);
    } catch (error) {
        return logError(error);
    }
}

const getUserSlider = async () => {
    try {
        return await API().get(`/user-slider`);
    } catch (error) {
        return logError(error);
    }
}


export { getSlider, getUserSlider }
