<template>
    <div
        :class="{ 'in-footer bg-white': isFooterPartner }"
        class="become-partner d-flex flex-column flex-lg-row"
    >
        <div class="content order-2 order-lg-1">
            <h5
                v-if="isFooterPartner"
                class="text-uppercase font-weight-600 mb-2"
            >
                BECOME a PARTNER
            </h5>
            <h4 v-else class="text-uppercase font-weight-600 mb-4">
                BECOME a PARTNER
            </h4>
            <p :class="[isFooterPartner ? 'mb-2' : 'mb-4']" class="text-gray">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <router-link
                class="btn btn-brand d-sm-inline-block px-sm-5"
                to="/contact-us"
                >Contact</router-link
            >
        </div>
        <div class="img order-1 order-lg-2 d-flex justify-content-center">
            <img
                src="@/assets/imgs/become-partner.png"
                alt="become a partner"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "BecomePartner",
    props: ["isFooterPartner"],
};
</script>
