import Home from "@/views/Home.vue";

const About = () =>
    import(/* webpackChunkName: "about" */ "@/views/About");

const ContactUs = () =>
    import(/* webpackChunkName: "contact" */ "@/views/ContactUs");

const PartnersHome = () =>
    import(/* webpackChunkName: "PartnersHome" */ "@/views/Partners");
const Partners = () =>
    import(/* webpackChunkName: "Partners" */ "@/views/Partners/Partners");
const Partner = () =>
    import(/* webpackChunkName: "Partner" */ "@/views/Partners/Partner");

const TherHome = () =>
    import(/* webpackChunkName: "TherHome" */ "@/views/Ther");
const Thers = () =>
    import(/* webpackChunkName: "Partners" */ "@/views/Ther/Thers");
const Ther = () =>
    import(/* webpackChunkName: "Partner" */ "@/views/Ther/Ther");

const Events = () =>
    import(/* webpackChunkName: "Events" */ "@/views/Events");
const EventsHome = () =>
    import(/* webpackChunkName: "EventsHome" */ "@/views/Events/Events");
const Event = () =>
    import(/* webpackChunkName: "Event" */ "@/views/Events/Event");

const FAQ = () =>
    import(/* webpackChunkName: "faq" */ "@/views/FAQ");
const Module = () =>
    import(/* webpackChunkName: "Module" */ "@/views/Module");

const Dashboard = () =>
    import(/* webpackChunkName: "dashboard-home" */ "@/views/Dashboard");

const MyAccount = () =>
    import(/* webpackChunkName: "MyAccount" */ "@/views/MyAccount");
const MyAccountProfile = () =>
    import(/* webpackChunkName: "MyAccountProfile" */ "@/views/MyAccount/MyAccountProfile");

const Favorites = () =>
    import(/* webpackChunkName: "Favorites" */ "@/views/MyAccount/Favorites");

const Modules = () =>
    import(/* webpackChunkName: "Modules" */ "@/views/MyAccount/Modules");

const MyModules = () =>
    import(/* webpackChunkName: "MyModules" */ "@/views/MyAccount/MyModules");

const CMEAccredited = () =>
    import(/* webpackChunkName: "CMEAccredited" */ "@/views/MyAccount/CMEAccredited");

const ChangePassword = () =>
    import(/* webpackChunkName: "ChangePassword" */ "@/views/MyAccount/ChangePassword");

const MyAccountQuiz = () =>
    import(/* webpackChunkName: "MyAccountQuiz" */ "@/views/MyAccount/Quiz");

const RecentlyVisited = () =>
    import(/* webpackChunkName: "RecentlyVisited" */ "@/views/MyAccount/RecentlyVisited");



const Quiz = () =>
    import(/* webpackChunkName: "quiz" */ "@/views/Quiz");

const UserInfo = () =>
    import(/* webpackChunkName: "user-info" */ "@/views/Quiz/Info");

const QuizQuestions = () =>
    import(/* webpackChunkName: "quiz-questions" */ "@/views/Quiz/QuizQuestions");

const Result = () =>
    import(/* webpackChunkName: "quiz-result" */ "@/views/Quiz/Result");

const Verification = () =>
    import(/* webpackChunkName: "Verification" */ "@/views/Verification");

const ServerError = () =>
    import(/* webpackChunkName: "serve-error" */ "@/views/ServerError");

const NotFound = () =>
    import(/* webpackChunkName: "not-found" */ "@/views/NotFound");

const NetworkError = () =>
    import(/* webpackChunkName: "network-error" */ "@/views/NetworkError");


const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/therapeutic-area",
        name: "Therapeutic",
        component: TherHome,
        children: [
            {
                path: "",
                component: Thers,
            },
            {
                path: "/therapeutic-area/:id",
                component: Ther,
            },
        ]
    },
    {
        path: "/events",
        name: "Events",
        component: Events,
        children: [
            {
                path: "",
                component: EventsHome,
            },
            {
                path: "/events/:id/:name",
                component: Event,
                meta: {
                    requiresAuth: true
                },
            },
        ]
    },
    {
        path: "/partners",
        name: "Partners",
        component: PartnersHome,
        children: [
            {
                path: "",
                component: Partners,
            },
            {
                path: "/partners/:id/:name",
                component: Partner,
                meta: {
                    requiresAuth: true
                },
            },
        ]
    },
    {
        path: "/contact-us",
        name: "Contact",
        component: ContactUs,
    },
    {
        path: "/faq",
        name: "FAQ",
        component: FAQ,
    },
    {
        path: "/module/:id/:code",
        name: "Module",
        component: Module,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
    },
    // Quiz
    {
        path: "/quiz",
        name: "Quiz",
        component: Quiz,
        children: [
            {
                path: "",
                component: UserInfo,
            },
            {
                path: "/quiz/:code",
                component: QuizQuestions,
            },
            {
                path: "/quiz/:code/result",
                component: Result,
            },
        ]
    },
    // My Account
    {
        path: "/my-account",
        name: "MyAccount",
        component: MyAccount,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "",
                component: MyAccountProfile,
            },
            {
                path: "/my-account/favorites",
                component: Favorites
            },
            {
                path: "/my-account/modules",
                component: Modules
            },
            {
                path: "/my-account/my-modules",
                component: MyModules
            },
            {
                path: "/my-account/cme-accredited",
                component: CMEAccredited
            },
            {
                path: "/my-account/change-password",
                component: ChangePassword
            },
            {
                path: "/my-account/quiz",
                component: MyAccountQuiz
            },
            {
                path: "/my-account/recently-visited",
                component: RecentlyVisited
            },
        ]
    },
    {
        path: "/verification",
        component: Verification,
    },
    {
        path: "/500",
        component: ServerError,
    },
    {
        path: "/network-error",
        component: NetworkError,
    },
    {
        path: "*",
        component: NotFound,
    },

];

export default routes;