import { API, logError } from "./index";

const getNotifications = async () => {
    try {
        return await API().get(`/notification`);
    } catch (error) {
        return logError(error);
    }
}


export { getNotifications }