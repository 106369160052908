<template>
    <div id="app" class="main-container">
        <Search />
        <Notify />
        <!-- classes="notifications" -->

        <MainHeader v-if="$store.state.isMainHeader" />
        <MobileMenu />
        <main
            :class="[$store.state.isMainHeader ? '' : 'border-top']"
            class="content-wrapper bg-white overflow-hidden">
            <router-view />
        </main>
        <MainFooter v-if="$store.state.isMainFooter" />

        <!-- Terms modal -->
        <TermsModal />

        <!-- ForgetPassModal -->
        <ForgetPassModal />

        <!-- MustLoginModal -->
        <MustLoginModal />

        <MustUpdateDataModal />

    </div>
</template>

<script>
import Search from '@/components/Search.vue';
import Notify from "@/components/Notify";
import MainHeader from "@/components/MainHeader";
import MobileMenu from "@/components/MobileMenu";
import MainFooter from "@/components/MainFooter";
import TermsModal from "@/components/TermsModal.vue";
import ForgetPassModal from "@/components/ForgetPassModal";
import MustLoginModal from "@/components/MustLoginModal";
import MustUpdateDataModal from "@/components/MustUpdateDataModal";

// import { sendFCMToken } from "@/API/firebase";

export default {
    components: {
        Search,
        Notify,
        MainHeader,
        MobileMenu,
        MainFooter,
        TermsModal,
        ForgetPassModal,
        MustLoginModal,
        MustUpdateDataModal
    },
    data() {
        return {
            internetNotified: false,
        };
    },
    methods: {
        checkInternetConnection() {
            // notify if no internet
            window.addEventListener("offline", () => {
                this.$notify({
                    type: "danger",
                    fixed: true,
                    text: this.$t("disconnected"),
                });
                // this.$router.push('/network-error');
            });

            // notify if no internet
            window.addEventListener("online", () => {
                this.$notify({
                    fixed: true,
                    text: this.$t("connected"),
                });
                // this.$router.push('/');
            });
        },
    },
    mounted() {
        this.checkInternetConnection();

        this.$store.dispatch('getContacts');

        // if (localStorage.getItem('DLPToken') && location.protocol == 'https:') {
        //     this.$store.dispatch('firebase/getUserNotifications');
        //     this.$store.dispatch('firebase/updateMessagingFCMToken', this);
        // }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base";
</style>
