<template>
    <header 
    :class="{'show-search' : isSearch}"
    class="main-header bg-white">
        <nav class="navbar navbar-expand-lg navbar-light py-3">
            <div class="container">
                <router-link
                    :to="`${isLogin ? '/dashboard' : '/'}`"
                    class="navbar-brand"
                >
                    <img src="@/assets/imgs/logo.svg" alt="DLP" />
                </router-link>
                <button
                    @click="isSMNavbarMenu = true"
                    :class="[
                        isLogin ? 'd-none d-sm-inline-block d-lg-none' : '',
                    ]"
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <!-- <button 
                @click="updateLanguage()" 
                :class="[isLogin ? 'd-inline-block d-sm-none' : 'd-none']"
                class="btn text-uppercase">
                    {{ lang == 'en'? 'ar' : 'en' }}
                </button> -->

                <button
                    @click="$bvModal.show('confirmation-modal')"
                    :class="[isLogin ? 'd-inline-block d-sm-none' : 'd-none']"
                    class="btn log-out"
                    type="button"
                >
                    <span>{{ $t('logout') }}</span>
                </button>

                <div
                    class="collapse navbar-collapse d-none d-lg-inline-flex"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav mx-5">
                        <li class="nav-item">
                            <router-link
                                :to="`${isLogin ? '/dashboard' : '/'}`"
                                class="nav-link"
                                exact
                                >{{ $t('home') }}</router-link
                            >
                        </li>
                        <li class="nav-item">
                            <router-link to="/about" class="nav-link"
                                >{{ $t('about') }}</router-link
                            >
                        </li>
                        <li class="nav-item">
                            <router-link to="/partners" v-if="$store.state.auth.isLogin" class="nav-link">
                                {{ $t('partners') }}
                            </router-link>
                            <a v-else 
                            @click="$bvModal.show('must-login-modal')" class="nav-link" href="javascript:;">
                                {{ $t('partners') }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <router-link to="/events" v-if="$store.state.auth.isLogin" class="nav-link">
                                {{ $t('events') }}
                            </router-link>
                            <a v-else 
                            @click="$bvModal.show('must-login-modal')" class="nav-link" href="javascript:;">
                                {{ $t('events') }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <router-link to="/contact-us" class="nav-link"
                                >{{ $t('contact') }}</router-link
                            >
                        </li>
                        <!-- <li class="nav-item">
                            <button @click="updateLanguage()" class="btn text-uppercase">
                                {{ lang == 'en'? 'ع' : 'en' }}
                            </button>
                        </li> -->
                    </ul>
                </div>

                <ul
                    v-if="isLogin"
                    class="
                        navbar-nav
                        d-none d-lg-inline-flex
                        align-items-center
                    "
                >
                    <li class="nav-item mr-2">
                        <button @click="$store.commit('layout/UPDATE_SEARCH')" class="btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.165"
                                height="24.165"
                                viewBox="0 0 24.165 24.165"
                            >
                                <path
                                    d="M19.122,15.907a9.557,9.557,0,1,0-3.217,3.214L22.389,25.6,25.6,22.388Zm-8.148.967a5.9,5.9,0,1,1,5.9-5.892,5.905,5.905,0,0,1-5.9,5.892Z"
                                    transform="translate(-1.44 -1.44)"
                                    fill="#c6c6c6"
                                />
                            </svg>
                        </button>
                    </li>
                    <li class="nav-item mx-2">
                        <router-link to="/my-account/favorites" class="btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25.705"
                                height="22.492"
                                viewBox="0 0 25.705 22.492"
                            >
                                <path
                                    d="M23.209,3.786a6.865,6.865,0,0,0-9.368.683l-.989,1.019-.989-1.019a6.865,6.865,0,0,0-9.368-.683A7.209,7.209,0,0,0,2,14.223l9.715,10.031a1.574,1.574,0,0,0,2.274,0L23.7,14.223a7.2,7.2,0,0,0-.492-10.437Z"
                                    transform="translate(0.001 -2.248)"
                                    fill="#c6c6c6"
                                    class="fill"
                                />
                            </svg>
                        </router-link>
                    </li>
                    <!-- notifications -->
                    <li class="nav-item mx-2 notifications btn py-3">
                        <b-nav-item-dropdown right>
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                <b-badge :class="{'d-none' : !unreadCount}" pill variant="primary">
                                    {{ unreadCount }}
                                </b-badge>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19.321"
                                    height="24.165"
                                    viewBox="0 0 19.321 24.165"
                                >
                                    <g transform="translate(-6.761 -3.93)">
                                        <path
                                            d="M17.515,31.537c1.878,0,2.905-1.329,2.905-3.2H14.6C14.6,30.208,15.631,31.537,17.515,31.537Z"
                                            transform="translate(-1.106 -3.442)"
                                            fill="#c6c6c6"
                                        />
                                        <path
                                            d="M25.837,21.826c-.93-1.226-2.76-1.945-2.76-7.435,0-5.635-2.488-7.9-4.808-8.443-.217-.054-.374-.127-.374-.356V5.416a1.474,1.474,0,1,0-2.947,0v.175c0,.223-.157.3-.374.356-2.325.55-4.808,2.808-4.808,8.443,0,5.49-1.83,6.2-2.76,7.435a1.2,1.2,0,0,0,.96,1.921H24.882A1.2,1.2,0,0,0,25.837,21.826Z"
                                            transform="translate(0 0)"
                                            fill="#c6c6c6"
                                        />
                                    </g>
                                </svg>
                            </template>
                            <template v-if="notifications.length">
                                <li v-for="(notification, index) in notifications" :key="index" class="dropdown-item notification-item d-flex py-2 mb-1" :class="{unread : notification.unread}">
                                    <div class="mx-2">
                                        <!-- :src="notification.icon" -->
                                        <img v-if="notification.icon" class="notification-icon" :src="notification.icon">
                                        <img v-else class="notification-icon" src="@/assets/imgs/avatar.png">
                                    </div>
                                    <div class="mx-2 w-100">
                                        <h6 class="title mb-0 whitespace-normal">{{ notification.title }}</h6>
                                        <small class="time d-block mb-1">
                                            {{ $moment(notification.created_at).fromNow() }}
                                        </small>
                                        <small class="d-block whitespace-normal">
                                            {{ notification.body }}
                                        </small>
                                    </div>
                                </li>
                            </template>
                            <template v-else>
                                <li class="dropdown-item notification-item text-center px-3">
                                    <p class="mb-0 py-3">{{ $t('noNotifications') }}</p>
                                </li>
                            </template>
                        </b-nav-item-dropdown>
                    </li>
                    <!-- user account -->
                    <li class="nav-item ml-4">
                        <b-nav-item-dropdown right ref="user2">
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                <radial-progress-bar
                                    id="account"
                                    :diameter="diameter"
                                    :completed-steps="completedSteps"
                                    :total-steps="10"
                                    startColor="#007EC6"
                                    stopColor="#007EC6"
                                    :strokeWidth="5"
                                    :innerStrokeWidth="5"
                                    innerStrokeColor="#f5f5f5"
                                >
                                    <img
                                        v-if="user.images"
                                        class="img-circle"
                                        width="50"
                                        height="50"
                                        :src="user.images"
                                        :alt="user.first_name || user.email"
                                    />
                                    <img
                                        v-else
                                        class="img-circle"
                                        width="50"
                                        height="50"
                                        src="@/assets/imgs/avatar.png"
                                        alt="avatar"
                                    />
                                </radial-progress-bar>
                            </template>
                            <li class="dropdown-item">
                                <span @click="closeDropdownMenu()">
                                    <router-link to="/my-account"
                                        >{{ $t('myAccount') }}</router-link
                                    >
                                </span>
                            </li>
                            <li class="dropdown-item">
                                <span @click="$bvModal.show('confirmation-modal')">
                                    <a href="javascript:;">{{ $t('logout') }}</a>
                                </span>
                            </li>
                        </b-nav-item-dropdown>
                        <b-tooltip
                            v-if="!isProfileCompleted"
                            target="account"
                            variant="light"
                        >
                            <router-link to="/my-account">
                                {{ $t('completeProfile') }}
                            </router-link>
                        </b-tooltip>
                    </li>
                </ul>

                <!-- not login -->
                <ul v-else class="navbar-nav d-none d-lg-inline-flex">
                    <li class="nav-item">
                        <button v-b-modal.login-modal class="btn">
                            <span class="mx-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.519"
                                    height="17.736"
                                    viewBox="0 0 15.519 17.736"
                                >
                                    <path
                                        data-name="Icon awesome-user"
                                        d="M7.76,8.868A4.434,4.434,0,1,0,3.326,4.434,4.434,4.434,0,0,0,7.76,8.868Zm3.1,1.109h-.579a6.03,6.03,0,0,1-5.051,0H4.656A4.657,4.657,0,0,0,0,14.632v1.441a1.663,1.663,0,0,0,1.663,1.663H13.856a1.663,1.663,0,0,0,1.663-1.663V14.632A4.657,4.657,0,0,0,10.863,9.977Z"
                                        fill="#007ec6"
                                    />
                                </svg>
                            </span>
                            <span class="mx-1 font-weight-normal">
                                {{ $t('signIn') }}
                            </span>
                        </button>
                    </li>
                    <li class="nav-item">
                        <button v-b-modal.sign-up-modal class="btn">
                            <span class="mx-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.519"
                                    height="17.736"
                                    viewBox="0 0 15.519 17.736"
                                >
                                    <path
                                        data-name="Icon awesome-lock"
                                        d="M13.856,7.76h-.831V5.265a5.265,5.265,0,1,0-10.531,0V7.76H1.663A1.663,1.663,0,0,0,0,9.422v6.651a1.663,1.663,0,0,0,1.663,1.663H13.856a1.663,1.663,0,0,0,1.663-1.663V9.422A1.663,1.663,0,0,0,13.856,7.76Zm-3.6,0H5.265V5.265a2.494,2.494,0,1,1,4.988,0Z"
                                        fill="#007ec6"
                                    />
                                </svg>
                            </span>
                            <span class="mx-1 font-weight-normal">
                                {{ $t('joinUs') }}
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
            <!-- dashboard mobile controls -->
            <div v-if="isLogin" class="container d-none d-sm-block d-lg-none">
                <ul
                    class="
                        d-flex
                        align-items-center
                        justify-content-center
                        w-100
                        py-2
                    "
                >
                    <li class="nav-item mr-1 mr-sm-3">
                        <button 
                        @click="$store.commit('layout/UPDATE_SEARCH')" 
                        class="btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.165"
                                height="24.165"
                                viewBox="0 0 24.165 24.165"
                            >
                                <path
                                    d="M19.122,15.907a9.557,9.557,0,1,0-3.217,3.214L22.389,25.6,25.6,22.388Zm-8.148.967a5.9,5.9,0,1,1,5.9-5.892,5.905,5.905,0,0,1-5.9,5.892Z"
                                    transform="translate(-1.44 -1.44)"
                                    fill="#c6c6c6"
                                />
                            </svg>
                        </button>
                    </li>
                    <li class="nav-item mx-1 mx-sm-3">
                        <router-link to="/my-account/favorites" class="btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25.705"
                                height="22.492"
                                viewBox="0 0 25.705 22.492"
                            >
                                <path
                                    d="M23.209,3.786a6.865,6.865,0,0,0-9.368.683l-.989,1.019-.989-1.019a6.865,6.865,0,0,0-9.368-.683A7.209,7.209,0,0,0,2,14.223l9.715,10.031a1.574,1.574,0,0,0,2.274,0L23.7,14.223a7.2,7.2,0,0,0-.492-10.437Z"
                                    transform="translate(0.001 -2.248)"
                                    fill="#c6c6c6"
                                    class="fill"
                                />
                            </svg>
                        </router-link>
                    </li>
                    <!-- notifications -->
                    <li class="nav-item mx-3 mx-sm-3 notifications pt-2">
                        <b-nav-item-dropdown right>
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                <b-badge :class="{'d-none' : !unreadCount}" pill variant="primary">
                                    {{ unreadCount }}
                                </b-badge>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19.321"
                                    height="24.165"
                                    viewBox="0 0 19.321 24.165"
                                >
                                    <g transform="translate(-6.761 -3.93)">
                                        <path
                                            d="M17.515,31.537c1.878,0,2.905-1.329,2.905-3.2H14.6C14.6,30.208,15.631,31.537,17.515,31.537Z"
                                            transform="translate(-1.106 -3.442)"
                                            fill="#c6c6c6"
                                        />
                                        <path
                                            d="M25.837,21.826c-.93-1.226-2.76-1.945-2.76-7.435,0-5.635-2.488-7.9-4.808-8.443-.217-.054-.374-.127-.374-.356V5.416a1.474,1.474,0,1,0-2.947,0v.175c0,.223-.157.3-.374.356-2.325.55-4.808,2.808-4.808,8.443,0,5.49-1.83,6.2-2.76,7.435a1.2,1.2,0,0,0,.96,1.921H24.882A1.2,1.2,0,0,0,25.837,21.826Z"
                                            transform="translate(0 0)"
                                            fill="#c6c6c6"
                                        />
                                    </g>
                                </svg>
                            </template>
                            <template v-if="notifications.length">
                                <li v-for="(notification, index) in notifications" :key="index" class="dropdown-item notification-item d-flex py-2 mb-1" :class="{unread : notification.unread}">
                                    <div class="mx-2">
                                        <!-- :src="notification.icon" -->
                                        <img v-if="notification.icon" class="notification-icon" :src="notification.icon">
                                        <img v-else class="notification-icon" src="@/assets/imgs/avatar.png">
                                    </div>
                                    <div class="mx-2 w-100">
                                        <h6 class="title mb-0 whitespace-normal">{{ notification.title }}</h6>
                                        <small class="time d-block mb-1">
                                            {{ $moment(notification.created_at).fromNow() }}
                                        </small>
                                        <small class="d-block whitespace-normal">
                                            {{ notification.body }}
                                        </small>
                                    </div>
                                </li>
                            </template>
                            <template v-else>
                                <li class="dropdown-item notification-item text-center px-3">
                                    <p class="mb-0 py-3">{{ $t('noNotifications') }}</p>
                                </li>
                            </template>
                        </b-nav-item-dropdown>
                    </li>
                    <!-- user account -->
                    <li class="nav-item ml-4 ml-sm-3">
                        <b-nav-item-dropdown right ref="user">
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                <radial-progress-bar
                                    id="account"
                                    :diameter="diameter"
                                    :completed-steps="completedSteps"
                                    :total-steps="10"
                                    startColor="#007EC6"
                                    stopColor="#007EC6"
                                    :strokeWidth="5"
                                    :innerStrokeWidth="5"
                                    innerStrokeColor="#f5f5f5"
                                >
                                    <img
                                        v-if="user.images"
                                        class="img-circle"
                                        width="50"
                                        height="50"
                                        :src="user.images"
                                        :alt="user.first_name || user.email"
                                    />
                                    <img
                                        v-else
                                        class="img-circle"
                                        width="50"
                                        height="50"
                                        src="@/assets/imgs/avatar.png"
                                        alt="avatar"
                                    />
                                </radial-progress-bar>
                            </template>
                            <li class="dropdown-item">
                                <span @click="closeDropdownMenu()">
                                    <router-link to="/my-account"
                                        >{{ $t('myAccount') }}</router-link
                                    >
                                </span>
                            </li>
                            <li class="dropdown-item">
                                <span @click="$bvModal.show('confirmation-modal')">
                                    <a href="javascript:;">{{ $t('logout') }}</a>
                                </span>
                            </li>
                        </b-nav-item-dropdown>
                        <b-tooltip
                            v-if="!isProfileCompleted"
                            target="account"
                            variant="light"
                        >
                            <router-link to="/my-account">
                                {{ $t('completeProfile') }}
                            </router-link>
                        </b-tooltip>
                    </li>
                </ul>
            </div>
            <!-- small screens navbar menu -->
                <!-- v-if="isSMNavbarMenu" -->
            <ul class="sm-navbar-menu navbar-nav d-lg-none"
            :class="[isSMNavbarMenu ? 'show' : '']">
                <li @click="isSMNavbarMenu = false" class="close-menu">X</li>
                <li class="nav-item">
                    <span @click="isSMNavbarMenu = false">
                        <router-link
                            :to="`${isLogin ? '/dashboard' : '/'}`"
                            exact
                            class="nav-link"
                            >{{ $t('home') }}</router-link
                        >
                    </span>
                </li>
                <li class="nav-item">
                    <span @click="isSMNavbarMenu = false">
                        <router-link to="/about" class="nav-link"
                            >{{ $t('about') }}</router-link
                        >
                    </span>
                </li>
                <li class="nav-item">
                    <span @click="isSMNavbarMenu = false">
                        <router-link to="/partners" v-if="$store.state.auth.isLogin" class="nav-link">
                            {{ $t('partners') }}
                        </router-link>
                        <a v-else 
                        @click="$bvModal.show('must-login-modal')" class="nav-link" href="javascript:;">
                            {{ $t('partners') }}
                        </a>
                    </span>
                </li>
                <li class="nav-item">
                    <span @click="isSMNavbarMenu = false">
                        <router-link to="/events" v-if="$store.state.auth.isLogin" class="nav-link">
                            {{ $t('events') }}
                        </router-link>
                        <a v-else 
                        @click="$bvModal.show('must-login-modal')" class="nav-link" href="javascript:;">
                            {{ $t('events') }}
                        </a>
                    </span>
                </li>
                <li class="nav-item">
                    <span @click="isSMNavbarMenu = false">
                        <router-link to="/contact-us" class="nav-link"
                            >{{ $t('contact') }}</router-link
                        >
                    </span>
                </li>
                <!-- <li class="nav-item">
                    <button @click="updateLanguage()" class="btn text-uppercase">
                        {{ lang == 'en'? 'ع' : 'en' }}
                    </button>
                </li> -->
                <li v-if="!isLogin" class="nav-item">
                    <span @click="isSMNavbarMenu = false">
                        <button v-b-modal.login-modal class="btn">
                            <span class="mx-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.519"
                                    height="17.736"
                                    viewBox="0 0 15.519 17.736"
                                >
                                    <path
                                        data-name="Icon awesome-user"
                                        d="M7.76,8.868A4.434,4.434,0,1,0,3.326,4.434,4.434,4.434,0,0,0,7.76,8.868Zm3.1,1.109h-.579a6.03,6.03,0,0,1-5.051,0H4.656A4.657,4.657,0,0,0,0,14.632v1.441a1.663,1.663,0,0,0,1.663,1.663H13.856a1.663,1.663,0,0,0,1.663-1.663V14.632A4.657,4.657,0,0,0,10.863,9.977Z"
                                        fill="#007ec6"
                                    />
                                </svg>
                            </span>
                            <span class="mx-1 font-weight-normal">
                                {{ $t('signIn') }}
                            </span>
                        </button>
                    </span>
                </li>
                <li v-if="!isLogin" class="nav-item">
                    <span @click="isSMNavbarMenu = false">
                        <button v-b-modal.sign-up-modal class="btn">
                            <span class="mx-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.519"
                                    height="17.736"
                                    viewBox="0 0 15.519 17.736"
                                >
                                    <path
                                        data-name="Icon awesome-lock"
                                        d="M13.856,7.76h-.831V5.265a5.265,5.265,0,1,0-10.531,0V7.76H1.663A1.663,1.663,0,0,0,0,9.422v6.651a1.663,1.663,0,0,0,1.663,1.663H13.856a1.663,1.663,0,0,0,1.663-1.663V9.422A1.663,1.663,0,0,0,13.856,7.76Zm-3.6,0H5.265V5.265a2.494,2.494,0,1,1,4.988,0Z"
                                        fill="#007ec6"
                                    />
                                </svg>
                            </span>
                            <span class="mx-1 font-weight-normal">
                                {{ $t('joinUs') }}
                            </span>
                        </button>
                    </span>
                </li>

                <li v-if="isLogin" class="nav-item mt-2">
                    <button
                        @click="$bvModal.show('confirmation-modal')"
                        :class="[isLogin ? 'd-inline-block d-lg-none' : 'd-none']"
                        class="btn log-out px-4" type="button">
                        <span>{{ $t('logout') }}</span>
                    </button>
                </li>
            </ul>
            <div
                v-if="isSMNavbarMenu"
                @click="isSMNavbarMenu = false"
                class="sm-navbar-menu-overlay"
            >
                <div class="mobile-menu-exit d-sm-none">
                    <span class="btn">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z"
                                transform="translate(-6 -6)"
                                fill="#35495e"
                            />
                        </svg>
                    </span>
                </div>
            </div>
        </nav>

        <LoginModal />
        <SignUpModal />

        <!-- ConfirmAction -->
        <ConfirmAction 
        :title="$t('logoutConfirm')"
        @confirmAction="confirmLogout($event)"/>

    </header>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";
import SignUpModal from "@/components/SignUpModal";
import LoginModal from "@/components/LoginModal";

import { logOutUser } from "@/API/auth";
import ConfirmAction from '@/components/ConfirmAction';

export default {
    name: "MainHeader",
    components: {
        RadialProgressBar,
        SignUpModal,
        LoginModal,
        ConfirmAction
    },
    data() {
        return {
            lang: localStorage.getItem('dlpLang') || 'en',
            isSMNavbarMenu: false,
            diameter: 60,
        };
    },
    computed: {
        completedSteps() {
            return this.isProfileCompleted ? 10 : 7
        },
        user() {
            return this.$store.state.auth?.user;
        },
        isLogin() {
            return this.$store.state.auth?.isLogin || false;
        },
        isSearch() {
            return this.$store.state?.layout?.isSearch;
        },
        notifications() {
            return this.$store.getters['firebase/notifications'];
        },
        unreadCount() {
            return this.$store.getters['firebase/unreadCount'];
        },
        isProfileCompleted() {
            return this.$store.getters['auth/isProfileCompleted'];
        }
    },
    methods: {
        updateLanguage() {
            this.updatingLang = true;
            const newLang = this.lang == 'en'? 'ar' : 'en';
            localStorage.setItem('dlpLang', newLang);
            location.reload();
        },
        closeDropdownMenu() {
            this.$refs.user.hide(true);
            this.$refs.user2.hide(true);
        },
        confirmLogout(confirm) {
            if (confirm) this.logOut();
        },
        logOut() {
            // this.$bvModal.show('confirmation-modal');
            logOutUser().then((res) => {
                if (res.status == 200) {
                    // console.log(res.data);
                    this.$store.dispatch("auth/logoutUser");
                    this.$router.push("/");
                    this.closeDropdownMenu();
                    this.$notify(res.data.message);
                    this.$bvModal.hide('confirmation-modal');
                    return;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res.response?.data?.message) {
                    const message = res.response.data.message;
                    console.log(message);
                    this.$notify(message);
                }
            });
        },
    },
    mounted() {
        if (window.innerWidth < 480) {
            this.diameter = 30;
        }
    },
};
</script>

<style lang="scss" scoped>
.nav-item.notifications {
    position: relative;
    .badge {
        position: absolute;
        top: -9px;
        right: -12px;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-content: center;
        justify-content: center;
    }
}
</style>