export default {
    namespaced: true,
    state: () => ({
        userDataCollected: false,
        collectedData: null,
        result: null,
        responseID: null,
        quizData: null,
        userData: null,
        answeredQuestions: []
    }),
    mutations: {
        UPDATERESULT(state, payload) {
            state.result = payload;
        },
        UPDATERESPONSEID(state, payload) {
            state.responseID = payload;
        },
        UPDATEQUIZDATA(state, payload) {
            state.quizData = payload;
        },
        TOGGLEUSERDATACOLLECTED(state, payload) {
            state.userDataCollected = payload.isCollected;
            state.collectedData = payload.collectedData;
        },
        UPDATEUERDATA(state, payload) {
            state.userData = payload;
        },
        UPDATEQUESTIONS(state, payload) {
            let existQ = false;
            const newAnswers = state.answeredQuestions.map(q => {
                if (q.id == payload.id) {
                    q.answer = payload.answer;
                    existQ = true;
                }
                return q;
            });

            state.answeredQuestions = newAnswers;

            if (!existQ) {
                state.answeredQuestions.push(payload);
            }
        },
    },
    actions: {},
}