import { sendFCMToken } from "@/API/firebase";
import { getNotifications } from "@/API/notifications";
import config from "@/config";

export default {
  namespaced: true,
  state: () => ({
    FCMToken: null,
    notifications: [],
    unreadCount: 0
  }),
  getters: {
    isFCMToken(state) {
      return state.FCMToken ? true : false
    },
    notifications(state) {
      return state.notifications || []
    },
    unreadCount(state) {
      return state.unreadCount || 0
    }
  },
  mutations: {
      UPDATE_FCM_TOKEN(state, payload) {
        state.FCMToken = payload;
      },
      UPDATE_NOTIFICATIONS(state, payload) {
        state.notifications = payload
      },
      UPDATE_UNREAD_COUNT(state, payload) {
        const unreadCount = state.unreadCount
        if (payload.plus) state.unreadCount = unreadCount + payload.count
        else if (payload.minus && unreadCount) state.unreadCount = unreadCount - payload.count
      }
  },
  actions: {
    getUserNotifications({ commit }) {
      getNotifications().then(res => {
        if (res.status == 200) {
          // console.log(res.data?.data);
          commit('UPDATE_NOTIFICATIONS', res.data?.data)
        }
      })
    },
    async updateMessagingFCMToken({ commit, dispatch }, vm) {

      let FCMToken = null;

      // Get FCM Token
      await vm.$messaging.getToken({ vapidKey: config.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
              console.log('FCM token genaerated', currentToken)
              FCMToken = currentToken
          } 
          else
            console.log('No registration token available. Request permission to generate one.')
        
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return;
      });

      if (FCMToken) {
        // Save FCM Token
        commit('UPDATE_FCM_TOKEN', FCMToken)
        
        // Send Token to the server
        const tokenData = { fcm_token: FCMToken }
  
        await sendFCMToken(tokenData).then(res => {
          // Init Messaging Notifications if reuquest is OK
          if (res.status == 200) dispatch('initNotifications', vm)
        })
      }
    },
    initNotifications({getters, commit, dispatch}, vm) {
      console.log('Notifications enabled');
      const currentNotifications = getters.notifications

      vm.$messaging.onMessage((payload) => {
        const newNotifiaction = payload.notification;
        console.log('Message received from store. ', newNotifiaction);

        newNotifiaction.created_at = new Date();
        newNotifiaction.unread = true;

        currentNotifications.unshift(newNotifiaction)
        commit('UPDATE_NOTIFICATIONS', currentNotifications)
        commit('UPDATE_UNREAD_COUNT', {
          plus: true,
          count: 1
        })
        dispatch('notifyBySound')

      });
    },
    notifyBySound() {
      document.getElementById('play').click();
    }
  },
}

