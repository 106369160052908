<template>
    <b-modal id="terms-modal" static no-close-on-backdrop centered hide-footer>
        <template #modal-header="{ close }">
            <div></div>
            <b-button size="sm" @click="close()"> X </b-button>
        </template>
        <h1 class="font-weight-600">{{ $store.state.termsTitle || title }}</h1>
        <pre>{{ $store.state.termsContent || content }}</pre>
    </b-modal>
</template>

<script>
    import { terms } from "@/API/terms";
    export default {
        data() {
            return {
                loading: false,
                title: this.$t('termsAndConditions'),
                content: null
            }
        },
        methods: {
            getTermsContent() {
                this.loading = true;
                terms().then(res => {
                    if (res.status == 200) {
                        const data = res.data?.data?.term;
                        // console.log('terms', data);
                        if (data) this.content = data[0].text_term
                    }

                    if (res?.response?.status == 404) {
                        this.$router.push("/404");
                        return;
                    }

                    if (res?.response?.status == 500) {
                        this.$router.push("/500");
                        return;
                    }

                    this.loading = false;
                });
            }
        },
        mounted() {
            this.getTermsContent();

            this.$root.$on("bv::modal::hide", () => {
                setTimeout(() => {
                    this.$store.commit('UPDATE_TERMS_MODAL', {
                        title: null,
                        content: null
                    });
                }, 300);
            });
        }
    };
</script>


